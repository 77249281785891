function ctaFull() {
	$('.c-cta-full__optional-link').on('click', function(e) {
		e.preventDefault();

		$('body').addClass('h-scroll-lock');
		$('.c-optional-popup__content').text(
			$(this)
				.siblings('.c-cta-full__optional-popup-content')
				.text(),
		);
		$('.c-optional-popup').fadeIn();

		$('.c-optional-popup').addClass('c-optional-popup--cta-opened');
	});

	$(document).on('click', function(event) {
		if (
			$(event.target).is('.c-cta-full__optional-link') === false &&
			$(event.target).is('.c-optional-popup__container') === false &&
			$('.c-optional-popup__container').has(event.target).length === 0 &&
			$('.c-optional-popup').hasClass('c-optional-popup--cta-opened')
		) {
			$('body').removeClass('h-scroll-lock');
			$('.c-optional-popup').fadeOut();

			$('.c-optional-popup').removeClass('c-optional-popup--cta-opened');
		}
	});

	$('.c-optional-popup__close-btn').on('click', function() {
		$('body').removeClass('h-scroll-lock');
		$('.c-optional-popup').fadeOut();

		$('.c-optional-popup').removeClass('c-optional-popup--cta-opened');
	});
}

export default ctaFull;
