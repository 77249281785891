function showJSMultiImages() {
	let jsMIComponentClass = 'c-js-multi-images';

	$(`.${jsMIComponentClass}__btn-wrapper`).on('click', function() {
		$(this)
			.siblings(`.${jsMIComponentClass}__video`)
			.children('.c-video__video')
			.trigger('play');
		$(this).fadeOut(800);
	});
}

export default showJSMultiImages;
