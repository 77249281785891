import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function showTestimonials() {
	Swiper.use([Autoplay, Navigation, Pagination]);

	if ($('.c-default-testimonials__stories-slide-item ').length <= 1) {
		$('.c-default-testimonials__stories-slider-wrapper').addClass(
			'c-default-testimonials__stories-slider-wrapper--disabled',
		);

		$('.c-default-testimonials__stories-slider-btn-prev').addClass(
			'c-default-testimonials__stories-slider-btn-prev--disabled',
		);
		$('.c-default-testimonials__stories-slider-btn-next').addClass(
			'c-default-testimonials__stories-slider-btn-next--disabled',
		);
	} else {
		$('.c-default-testimonials__stories-slider-wrapper').removeClass(
			'c-default-testimonials__stories-slider-wrapper--disabled',
		);

		$('.c-default-testimonials__stories-slider-btn-prev').removeClass(
			'c-default-testimonials__stories-slider-btn-prev--disabled',
		);
		$('.c-default-testimonials__stories-slider-btn-next').removeClass(
			'c-default-testimonials__stories-slider-btn-next--disabled',
		);

		new Swiper('.c-default-testimonials__stories-slider-container', {
			loop: false,
			speed: 600,
			autoplay: {
				delay: 7000,
			},
			slidesPerView: 1,
			spaceBetween: 48,
			navigation: {
				prevEl: '.c-default-testimonials__stories-slider-btn-prev',
				nextEl: '.c-default-testimonials__stories-slider-btn-next',
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
		});
	}
}

export default showTestimonials;
