/*
 * Headroom
 *
 * Calling the Headroom.js object
 */
// var Headroom = require('headroom.js');

function headRoom() {
	let selector = '.l-header__menu-btn';
	let activeClass = 'is-active';
	let bodyActiveClass = 'h-scroll-lock nav-active';
	let headerActiveClass = 'l-header--menu-btn-active';
	let navMenu = '.js-slide-out';
	let navMenuActiveClass = 'c-slide-out--active';

	$(selector).click(function(e) {
		e.preventDefault();

		$(selector).toggleClass(activeClass);
		$('.js-body').toggleClass(bodyActiveClass);
		$('.js-header').toggleClass(headerActiveClass);
		$(navMenu).toggleClass(navMenuActiveClass);

		if ($(selector).hasClass(activeClass)) {
			$(selector)
				.children('.l-header__menu-btn--menu')
				.fadeOut(400);
			$(selector)
				.children('.l-header__menu-btn--close')
				.fadeIn(400);
		} else {
			$(selector)
				.children('.l-header__menu-btn--close')
				.fadeOut(300);
			$(selector)
				.children('.l-header__menu-btn--menu')
				.fadeIn(300);
		}
	});

	$(window).on('resize', function() {
		if ($(window).width() >= 992) {
			if ($('.l-header__menu-btn').hasClass('is-active')) {
				$('.l-header__menu-btn').trigger('click');
			}
		}
	});

	$(selector).removeClass(activeClass);
	$('.js-body').removeClass(bodyActiveClass);
	$('.js-header').removeClass(headerActiveClass);
	$(navMenu).removeClass(navMenuActiveClass);
}

export default headRoom;
