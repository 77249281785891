import Swiper, { Pagination } from 'swiper';

function showJSTextImage() {
	Swiper.use([Pagination]);

	const componentClass = 'c-js-text-image';
	let titlesArray = [];

	$(`.${componentClass}__location-slide-title`).each(function() {
		titlesArray.push($.trim($(this).text()));
	});

	let swiper = new Swiper('.swiper-container', {
		loop: true,
		speed: 400,
		spaceBetween: 0,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			renderBullet: function(index, className) {
				return (
					'<div class="' +
					className +
					'">' +
					titlesArray[index] +
					'</div>'
				);
			},
		},
		breakpoints: {
			768: {
				spaceBetween: 60,
			},
		},
	});
}

export default showJSTextImage;
