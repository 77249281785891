if (typeof Object.assign != 'function') {
	Object.assign = function(target) {
		if (target == null) {
			throw new TypeError('Cannot convert undefined or null to object');
		}

		target = Object(target);
		for (var index = 1; index < arguments.length; index++) {
			var source = arguments[index];
			if (source != null) {
				for (var key in source) {
					if (Object.prototype.hasOwnProperty.call(source, key)) {
						target[key] = source[key];
					}
				}
			}
		}
		return target;
	};
}

// import './util/cookieFromUrl';
import './util/modernizr';
import './util/objectFillPolyfill';
import '../style.scss';
import './core/core';
/** Responsive Images Polyfill */
import 'picturefill';

import jQuery from 'jquery';
import Rellax from 'rellax';

// import showInfoVideo from '../components/blocks-common/info-video/info-video';
import showJSSliderWork from '../components/blocks-client/js-slider-work/js-slider-work';
import showJSTeamMembers from '../components/blocks-client/js-team/js-team';
import textTransformIntoOneLine from '../components/blocks-client/js-text/js-text';
import showJSTextIcons from '../components/blocks-client/js-text-icons/js-text-icons';
import showJSTextImage from '../components/blocks-client/js-text-image/js-text-image';
import showJSMultiImages from '../components/blocks-client/js-multi-images/js-multi-images';
import showJSTextMultiImages from '../components/blocks-client/js-text-multi-images/js-text-multi-images';
import cardsHolderViewMore from '../components/blocks-default/default-image-cards/default-image-cards';
import showImagesSlider from '../components/blocks-default/default-slider-images/default-slider-images.js';
import showTestimonials from '../components/blocks-default/default-testimonials/default-testimonials.js';
import showAccordion from '../components/objects/accordion/accordion';
import showRelatedArticles from '../components/objects/post/related-articles/related-articles';
import ctaFull from '../components/objects/cta/cta-full/cta-full';
// import mapBlock from '../components/objects/map/map';
import navigateCategory from '../components/objects/nav/nav-category/nav-category';
import navMob from '../components/objects/nav/nav-mobile/nav-mobile';
import scrollToTop from '../components/objects/scroll-to-top/scroll-to-top';
import searchBar from '../components/objects/search/search-bar/search-bar';
import { navToggler } from '../components/objects/slide-out/slide-out';
import socialShare from '../components/objects/social/social-share/social-share';
import headRoom from '../layouts/header/header';
import parallaxScroll from './custom/parallax/parallaxscroll';
import scrollToAnchor from './custom/scroll/scroll-to-anchor';
import showOverviewBlog from './pages/overview-blog';
import showSingleBlog from './pages/single-blog';

// functions below are only run if the component exists (verified through a "data-module" in the html)
// in the ajax container or page. Barba.js instantiates the functions below on container reload as well.

// Place in alphabetical order
const app = {
	cardsHolderViewMore,
	ctaFull,
	showAccordion,
	headRoom,
	navigateCategory,
	searchBar,
	scrollToTop,
	socialShare,
	navToggler,
	// mapBlock,
	// showInfoVideo,
	showOverviewBlog,
	showSingleBlog,
	showJSMultiImages,
	showRelatedArticles,
	showJSSliderWork,
	showJSTeamMembers,
	showJSTextIcons,
	showJSTextImage,
	showJSTextMultiImages,
	textTransformIntoOneLine,
	showTestimonials,
	showImagesSlider,
};

let calledModulesNames = [];
app.instantiate = function(elem) {
	const $this = $(elem);
	const module = $this.attr('data-module');

	if (module === undefined) {
		throw 'Module not defined (use data-module="")';
	} else if (module in app) {
		if ($this.attr('data-initialized') === 'true') {
			return;
		}
		new app[module](elem);
		$this.attr('data-initialized', true);
	} else {
		throw "Module '" + module + "' not found";
	}
};

jQuery(document).ready(function() {
	scrollToAnchor($);
	navMob($);

	// Component functions that should only run if the component exists in the ajax container or page.
	$('[data-module]').each(function() {
		if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
			app.instantiate(this);

			calledModulesNames.push($(this).data('module'));
		}
	});

	// Parallax. Move to different folder
	$(window).on('load', function() {
		let rellax = new Rellax('.rellax', {
			center: true,
		});
	});
});

jQuery(window).scroll(function() {
	parallaxScroll(jQuery);

	let scroll = $(window).scrollTop();

	if (scroll >= 10) {
		$('.l-banner').addClass('l-banner--not-top');
		$('.l-header').addClass('l-headroom--not-top');
	} else {
		$('.l-banner').removeClass('l-banner--not-top');
		$('.l-header').removeClass('l-headroom--not-top');
	}
});
