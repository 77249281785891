/*
 * ScrollToTop
 *
 * runs the funcitonality of the scroll to top button that appears at the bottom right of the website
 */
/* eslint-disable */

function scrollToTop() {
	let speed = 600;
	let selector = '.js-scroll-to-top';
	let scrollTo = 0;

	$(selector).on('click', function(e) {
		e.preventDefault();

		$('html, body').animate(
			{
				scrollTop: scrollTo,
			},
			speed,
		);
		return false;
	});
}

export default scrollToTop;
