function showJSTextMultiImages() {
	const componentClass = 'c-js-text-multi-images';

	$(`.${componentClass}__expand-btn`).on('click', function() {
		$(this).toggleClass(`${componentClass}__expand-btn--active`);
		$(this)
			.siblings(`.${componentClass}__info-main-wrapper`)
			.slideToggle();
	});

	$(window).on('resize', function() {
		$(`.${componentClass}__expand-btn`).each(function() {
			if ($(window).outerWidth() >= 544) {
				if (
					!$(this).hasClass(`${componentClass}__expand-btn--active`)
				) {
					$(this)
						.siblings(`.${componentClass}__info-main-wrapper`)
						.slideDown();
				}

				if ($(window).outerWidth() >= 768) {
					$(`.${componentClass}__info-main-wrapper`).css('display', 'flex');
				} else {
					$(`.${componentClass}__info-main-wrapper`).css('display', 'block');
				}
			} else {
				if ($(this).hasClass(`${componentClass}__expand-btn--active`)) {
					$(this)
						.siblings(`.${componentClass}__info-main-wrapper`)
						.slideDown();
				} else {
					$(this)
						.siblings(`.${componentClass}__info-main-wrapper`)
						.slideUp();
				}
			}
		});
	});
}

export default showJSTextMultiImages;
