function showAccordion($elm) {
	$($elm)
		.find('.js-accordion')
		.click(function(e) {
			e.preventDefault();
			$(this)
				.parent()
				.parent()
				.toggleClass('is-active');
			$(this)
				.parent()
				.siblings('.c-accordion__main-info')
				.find('.c-accordion__content')
				.slideToggle('fast');
		});

	$('.c-accordion__title').on('click', function() {
		$(this)
			.parent()
			.siblings('.c-accordion__track')
			.find('.js-accordion')
			.trigger('click');
	});
}

export default showAccordion;
