import Swiper, { Navigation, Scrollbar } from 'swiper';

function showJSSliderWork() {
	Swiper.use([Scrollbar, Navigation]);

	let swiper = new Swiper('.swiper-container', {
		speed: 1000,
		slidesPerView: 1,
		centeredSlides: true,
		centeredSlidesBounds: true,
		spaceBetween: 120,
		navigation: {
			nextEl: '.c-js-slider-work__slider-navigation-btn--next',
			prevEl: '.c-js-slider-work__slider-navigation-btn--prev',
		},
		scrollbar: {
			el: '.swiper-scrollbar',
			draggable: true,
			hide: false,
		},
		breakpoints: {
			992: {
				width: 720,
			},
		},
	});
}

export default showJSSliderWork;
