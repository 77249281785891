/*
 * textTransformIntoOneLine
 *
 * runs the functionality of wrapping each line in a span
 */
/* eslint-disable */

function textTransformIntoOneLine() {
	var spanInserted = $('#someText')
		.html()
		.split(' ')
		.join(' </span><span>');
	var wrapped = '<span>'.concat(spanInserted, '</span>');
	$('#someText').html(wrapped);
	var refPos = $('#someText span:first-child').position().top;
	var newPos;
	$('#someText span').each(function(index) {
		newPos = $(this).position().top;
		if (index == 0) {
			return;
		}
		if (newPos == refPos) {
			$(this).prepend(
				$(this)
					.prev()
					.text() + ' ',
			);
			$(this)
				.prev()
				.remove();
		}
		refPos = newPos;
	});
}

export default textTransformIntoOneLine;
